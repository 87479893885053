<style lang="scss" scoped>
.pay_success_page {
	.icon {
		width: 1.45rem;
		height: 1.45rem;
		margin: 1.6rem auto 0.5rem;
		background: url(../assets/icon/success.png);
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;
	}
	.text {
		font-size: 0.3rem;
		color: #052433;
		line-height: 0.3rem;
		font-weight: bold;
		text-align: center;
	}
}
</style>

<template>
	<div class="pay_success_page">
		<div class="icon"></div>
		<div class="text">支付成功</div>
	</div>
</template>

<script>
export default {
	name: 'paySuccess', // 支付成功页面
	data() {
		return {};
	},
	created() {
		console.log(this.$route.params);
	},
	methods: {},
};
</script>
